import {Button} from '@material-ui/core';
import { useState } from 'react'

const Pressme = ({ bgColor }) => {

  return (
    <container background-color='blue'>
      <div style = {{}} ><Button style = {{display:'block', marginLeft:'auto', marginRight:'auto', backgroundColor:'blue', color:'red'
      }  }> Press me </Button></div>
    </container>
  )
}

Pressme.defaultProps = {
    // document.body.backgroundColor = { }
}

export default Pressme



