import { autocompleteClasses } from "@mui/material"

const Footer = () => {
  return (
    <div style={{margin:'auto', width:'50%'}}>
        <h3 style={{textAlign:'center'}}>Eat foots!!</h3>
    </div>
  )
}

export default Footer