import { useState  } from 'react'
import Test from './components/Test'
import Pressme from './components/Pressme'
import Footer from './components/Footer'
import Header from './components/Header'
import CopiedNavbar from './components/CopiedNavbar'

//document.body.style.backgroundColor = "purple";


function App() {
  const [bgColor, setBgColor] = useState(false)
  //document.body.style.backgroundColor = {bgColor ? 'green' : 'purple'};
  document.body.style.backgroundColor = 'purple'
  
  return (
    <div className="container"  >
      <CopiedNavbar />
      <Header />
      <Pressme  />
      <Footer/>
    </div>
  );
}

export default App;
